import { User } from "./user";
import { StateElections } from './stateElections';
import { Post } from './post';
import { Assembly } from './assembly';

export class Elections {

    public eleNid: number; 
    public eleCdescription: string;
    public eleNcantVacants: number
    public eleNcantApplicants: number
    public postulate: Post;
    public stateElection: StateElections;
    public eleNtimeDuration: number
    public eleOstate: boolean;
    public user: User;
    public eleDdateModified: Date;
    public eleDdateCreate: Date;
    public assembly: Assembly;

     constructor(pEleNid?,
        pEleCdescription?,
        pEleNcantVacants?,
        pEleNcantApplicants?,
        pPost?,
        pStateElection?,
        pEleNtimeDuration?,
        pEleOstate?,
        pUser?,
        pEleDdateModified?,
        pEleDdateCreate?,
        pAssembly?) {        
            this.eleNid = pEleNid;
            this.eleCdescription = pEleCdescription;
            this.eleNcantVacants = pEleNcantVacants;
            this.eleNcantApplicants = pEleNcantApplicants;
            this.postulate = pPost;
            this.stateElection = pStateElection;
            this.eleNtimeDuration = pEleNtimeDuration;
            this.eleOstate = pEleOstate;
            this.user = pUser;
            this.eleDdateModified = pEleDdateModified;
            this.eleDdateCreate = pEleDdateModified;
            this.assembly = pAssembly;
     }
}