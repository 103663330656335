import { User } from './user';
import { Questions } from './questions';

export class Answers {

    public ansNid: number;
    public ansCdescription: string;
    public ansOstate: boolean;
    public ansDdateCreate: Date;
    public ansDdateModified: Date;

    public user: User;    

    constructor(pAnsNid?,
        pAnsCdescription?,
        pAnsOstate?,
        pAnsDdate_create?,
        pAnsDdate_modified?,pUser?)
    {
        this.ansNid = pAnsNid,
        this.ansCdescription = pAnsCdescription,
        this.ansOstate = pAnsOstate;
        this.ansDdateCreate = pAnsDdate_create;
        this.ansDdateModified = pAnsDdate_modified;
        this.user = pUser;        
    }

    

}