import { User } from './user';
import { TypeIdentification } from './typeIdentification';
import { Agency } from './agency';


export class Assistant {
    public assNid: number;
    public assNidentificacion: number;
    public assCname: string;
    public assCagency: string;
    public assDdateModified: Date;
    public assDdateCreate: Date;
    public user: User;
    public typeIdentification: TypeIdentification;
    public assOstate: boolean;
    public assCemail: string;
    public assCphone: string;
    public agency: Agency;
    public assSinRegister: boolean;
    public token: string;
    public uaaCphoto: string;


    constructor(assNid?, assNidentificacion?, assCname?, assCagency?,
        assDdateModified?, assDdateCreate?, user?, typeIdentification?,assOstate?,assCemail?,assCphone?,agency?, assSinRegister?, token?, uaaCphoto?) {

        this.assNid = (assNid == null ? 0 : assNid);
        this.assNidentificacion = assNidentificacion;
        this.assCname = assCname;
        this.assCagency = assCagency;
        this.assDdateModified = assDdateModified;
        this.assDdateCreate = assDdateCreate;
        this.user = (user == null ? new User() : user);
        this.typeIdentification = (typeIdentification == null ? new TypeIdentification() : typeIdentification);
        this.assOstate = assOstate;
        this.assCemail = assCemail;
        this.assCphone = assCphone;
        this.agency = agency;
        this.assSinRegister = assSinRegister;
        this.token = token;
        this.uaaCphoto = uaaCphoto;
    }
}
