import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalService } from 'src/components/modal/modal.service';

import { LoginService } from 'src/services/login.service';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { AssemblyService } from 'src/services/assembly.service';
import { NavbarComponent } from 'src/components/navbar/navbar.component';
import { TypeIdentificationService } from 'src/services/typeIdentification.service';
import { RolService } from 'src/services/rol.service';
import { QuestionsService } from 'src/services/questions.services';
import { AnswersService } from 'src/services/answers.service';
import { DataService } from 'src/services/data.service';
import { AssistanceService } from 'src/services/assistance.service';
import { AssemblyAssistantService } from 'src/services/asemblyassistante.service';
import locales from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ElectionsService } from 'src/services/elections.service';
import { UppercaseInputDirective } from 'src/directives/uppercase-input.directive';

registerLocaleData(locales, 'es');

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        UppercaseInputDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [
        LoginService,
        ModalService,
        UserService,
        AssemblyService,
        TypeIdentificationService,
        RolService,
        DataService,
        AssistanceService,
        AssemblyAssistantService,
        QuestionsService,
        AnswersService,
        DataService,
        ElectionsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }