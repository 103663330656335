import { Assembly } from '../models/assembly';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map  } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ShareModule } from 'src/modules/share/share.module';

@Injectable({
    providedIn: "root"
  })

export class AssemblyService {

    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }

    selectAssembly(assembly?: Assembly): Promise<Assembly[]> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        
        let path = environment.apiUrl + '/api/Assembly/assemblies';
        path += (assembly.asaOstate != null) ? '?AsaOstate=' + assembly.asaOstate : '';
        
        return this.http.get(path, requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), () => this.showSpinner.next(false)
            ),
            map((data: Assembly[]) => 
            data.map(
                (item: Assembly) => 
                    new Assembly(
                    item.asaNid,item.asaCname,item.asaCdescription,
                    item.asaDdateAssembly,item.asaDdateHourInitial,
                    item.asaDdateHourFinalition,item.asaOstate,
                    item.user,item.asaDdateModified)    
                )
            )
        ).toPromise();
    }

    saveAssembly(objAssembly: Assembly): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = "";
        
        if(objAssembly.asaNid == 0){
            path = environment.apiUrl + '/api/Assembly/create';
        } 
        else{
            path = environment.apiUrl + '/api/Assembly/update';
        }

        return this.http.post(path, objAssembly, requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
        ).toPromise();
    }

    
    authHeaders() {
        return {
            Authorization: `bearer ` + new ShareModule().findToken(),
        }
    }
}