import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { TypeIdentification } from 'src/models/typeIdentification';
import { ShareModule } from 'src/modules/share/share.module';

@Injectable({
    providedIn: "root"
})
export class TypeIdentificationService {


    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    get(typeIdentification: TypeIdentification): Promise<TypeIdentification[]> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/TypeIdentification/typesidentifications?';
        path += (typeIdentification.tipOstate != null) ? 'TipOstate=' + typeIdentification.tipOstate : '';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: TypeIdentification[]) =>
                    data.map(
                        (item: TypeIdentification) =>
                            new TypeIdentification(item.tipNid, item.tipCdescription, item.tipOstate)
                    )
                )
            ).toPromise();
    }
    
    authHeaders() {
        return {
            Authorization: 'bearer ' + new ShareModule().findToken(),
        }
    }
}