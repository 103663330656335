import { Elections } from './elections';
import { AssemblyAssistant } from './assemblyassistant';

export class ElectionsPostulate {

    public ueaNid: number;
    public ueaNidPost: number;
    public election: Elections;
    public assemblyAssistant: AssemblyAssistant[];

    constructor(UeaNid?, pUeaNidPost?, elections?, assemblyAssistant?) {
        this.ueaNid = UeaNid;
        this.ueaNidPost = pUeaNidPost;
        this.election = elections;
        this.assemblyAssistant = assemblyAssistant;
    }
}