import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", loadChildren: "../pages/login/login.module#LoginModule" },
  { path: "home", loadChildren: "../pages/home/home.module#HomeModule" },
  { path: "assembly", loadChildren: "../pages/assembly/assembly.module#AssemblyModule" },
  { path: "assistant", loadChildren: "../pages/assistant/assistant.module#AssistantModule" },
  { path: "home", loadChildren: "../pages/home/home.module#HomeModule" },
  { path: "user", loadChildren: "../pages/user/user.module#UserModule" },
  { path: "camera", loadChildren: "../components/camera/camera.module#CameraModule" },
  { path: "questions", loadChildren: "../pages/questions/questions.module#QuestionsModule" },
  { path: "elections", loadChildren: "../pages/elections/elections.module#ElectionsModule" },
  { path: "postulate", loadChildren: "../pages/postulate/postulate.module#PostulateModule" },
  { path: "reports", loadChildren: "../pages/reports/reports.module#ReportsModule" },
  { path: "loadmassive", loadChildren: "../pages/loadmassive/loadmassive.module#LoadMassiveModule" },
  { path: "register", loadChildren: "../pages/register/register.module#RegisterModule" },
  { path: "agency", loadChildren: "../pages/agency/agency.module#AgencyModule" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
