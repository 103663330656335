import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Questions } from 'src/models/questions';
import { ShareModule } from 'src/modules/share/share.module';
import { QuestionAnswers } from 'src/models/questionanswers';
import { Assembly } from 'src/models/assembly';

@Injectable({
    providedIn: "root"
})


export class QuestionsService {
    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    getQuestions(question: Questions): Promise<Questions[]> {
        
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Question/questions';
        path += (question.queOstate != null) ? '?QueOstate=' + question.queOstate : '';
        path += (question.assembly.asaNid != null) ? '?assembly.AsaNid=' + question.assembly.asaNid : ''; 

        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Questions[]) =>
                    data.map(
                        (item: Questions) =>
                            new Questions(item.queNid, item.queCdescription,
                                item.queOstate,
                                item.queDdateModified,
                                item.queOactiveQuestion,
                                item.queDdateCreate,
                                item.user,
                                item.assembly,
                                item.queNtimeDuration,
                                item.typeQuestion
                            )
                    )
                )
            ).toPromise();
    }

    getQuestionsActive(asaNid: Number): Promise<QuestionAnswers> {
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Question/questionactive?asaNid=' + asaNid;
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map(resp => {
                    return <QuestionAnswers>resp;
                 }
                )
            ).toPromise();
    }

    saveQuestions(objQuestion: Questions): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = "";

        if (objQuestion.queNid == 0) {
            path = environment.apiUrl + '/api/Question/create';
        }
        else {
            path = environment.apiUrl + '/api/Question/update';
        }

        return this.http.post(path, objQuestion, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }

   

    authHeaders() {
        var token = new ShareModule().findToken();
        return {
            Authorization: `bearer ` + token,
        }
    }

}
