import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'src/components/modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from 'src/components/alert/alert.component';
import { FilterPipe } from 'src/filter/filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { OnlyNumber } from 'src/directives/onlynumber.directive';
import { InputMaxLengthDirective } from 'src/directives/input-max-length.directive';


@NgModule({
  declarations: [
    AlertComponent,
    FilterPipe, InputMaxLengthDirective, OnlyNumber
  ],
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    InputMaxLengthDirective, OnlyNumber,
    AlertComponent,
    FilterPipe,
    NgbModule
  ]
})

export class ShareModule {


  public saveToken(token) {
    document.cookie = "token=" + token;
  }

  public findToken() {
    return this.getCookie("token")
  }

  public cleanStorage() {
    this.removeCookies();
  }

  public validateDataStorage() {
    if (this.getCookie("isLogin") == null)
      return false;
    else return true;
  }

  public setReload(isReload) {
    this.delete_cookie("isReload");
    document.cookie = "isReload=" + isReload;

  }
  public getReload() {
    return Number(this.getCookie("isReload"));
  }

  public setIsLogin(isLogin) {
    document.cookie = "isLogin=" + isLogin;

  }

  public getIsLogin() {
    return Boolean(this.getCookie("isLogin"));
  }

  public setHome(isHome) {
    document.cookie = "isHome=" + isHome;
  }

  public getHome() {
    return this.getCookie("isHome");
  }

  public setUser(usuNid) {
    document.cookie = "usuNid=" + usuNid;
  }

  public getUser() {
    return Number(this.getCookie("usuNid"));
  }

  public setAssistant(uaaNid) {
    document.cookie = "uaaNid=" + uaaNid;
  }

  public getAssistant() {
    return this.getCookie("uaaNid");
  }

  public setDocAssistant(doc) {
    document.cookie = "doc=" + doc;
  }

  public getDocAssistant() {
    return this.getCookie("doc");
  }

  public setAssNid(assNid){
    document.cookie = "assNid=" + assNid
  }

  public getAssNid(){
    return this.getCookie("assNid");
  }


  public setRol(rolNid) {
    document.cookie = "rolNid=" + rolNid;
  }

  public getRol() {
    return Number(this.getCookie("rolNid"));
  }

  public setQuestions(queNid) {
    var lsQueNid = this.getQuestions();
    if (lsQueNid == null)
      lsQueNid = Array<Number>();

    lsQueNid.push(queNid);

    document.cookie = "lsQueNid=" + JSON.stringify(lsQueNid);
  }

  public getQuestions() {
    return JSON.parse(this.getCookie("lsQueNid"));
  }

  public clearQuestions() {
    this.delete_cookie("lsQueNid");
  }

  public setElections(eleNid) {
    
    var lsEleNid = this.getElections();
    if (lsEleNid == null)
      lsEleNid = Array<Number>();

    lsEleNid.push(eleNid);

    document.cookie = "lsEleNid=" + JSON.stringify(lsEleNid);
  }

  public getElections() {
    return JSON.parse(this.getCookie("lsEleNid"));
  }

  public clearElections() {
    this.delete_cookie("lsEleNid");
  }


  public delete_cookie(name) {
    document.cookie = name + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }

  public getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  public removeCookies() {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
      var key = multiple[i].split("=");
      document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }
  }


}