import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShareModule } from 'src/modules/share/share.module';
import { Post } from 'src/models/post';
import { tap, map } from 'rxjs/operators';
import { Elections } from 'src/models/elections';
import { ElectionsPostulate } from 'src/models/electionsPostulate';
import { Voting } from 'src/models/voting';
import { Assistant } from 'src/models/assistant';
import { AssemblyAssistant } from 'src/models/assemblyassistant';
import { ElectionsApplicant } from 'src/models/electionsApplicant';

@Injectable({
    providedIn: "root"
})


export class ElectionsService {
    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    getPost(objPost) {
        //
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Postulate/postulates' + (objPost.posOstate == null ? '' : '?PosOstate=' + objPost.posOstate);
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Post[]) =>
                    data.map(
                        (item: Post) =>
                            new Post(item.posNid,
                                item.posCdescription,
                                item.posOstate,
                                item.user,
                                item.posDdateModified,
                                item.posDdateCreate
                            )
                    )
                )
            ).toPromise();

    }

    getElections(objElections) {
        //
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Election/elections'

        if (objElections.eleOstate != null && objElections.assembly.asaNid == null) {
            path+= '?EleOstate=' + objElections.eleOstate;
        }else if(objElections.eleOstate == null && objElections.assembly.asaNid != null){
            path+= '?AsaNid=' + objElections.assembly.asaNid;
        }
        else if (objElections.eleOstate != null && objElections.assembly.asaNid != null){
            path+= '?EleOstate=' + objElections.eleOstate + '&AsaNid=' + objElections.assembly.asaNid;

        }
        
        //(objElections.AsaNid == null 
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Elections[]) =>
                    data.map(
                        (item: Elections) =>
                            new Elections(item.eleNid,
                                item.eleCdescription,
                                item.eleNcantVacants,
                                item.eleNcantApplicants,
                                item.postulate,
                                item.stateElection,
                                item.eleNtimeDuration,
                                item.eleOstate,
                                item.user,
                                item.eleDdateModified,
                                item.eleDdateCreate,
                                item.assembly
                            )
                    )
                )
            ).toPromise();

    }

    getElectionsActive(objElections: Elections) {
        let path = environment.apiUrl + '/api/Election/getElectionsApplicants' + (objElections.assembly.asaNid == null ? '' : '?asaNid=' + objElections.assembly.asaNid);
        path += (objElections.stateElection == null ? '' : '&staNid=' + objElections.stateElection);

        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: ElectionsPostulate[]) =>
                    data.map(
                        (item: ElectionsPostulate) =>
                            new ElectionsPostulate(item.ueaNid, item.ueaNidPost, item.election, item.assemblyAssistant)
                    )
                )
            ).toPromise();

    }

    saveElections(objElections): Promise<any> {
        //
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = "";

        if (objElections.eleNid == 0) {
            path = environment.apiUrl + '/api/Election/create';
        }
        else {
            path = environment.apiUrl + '/api/Election/update';
        }

        return this.http.post(path, objElections, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }


    setResult(result: Voting): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = environment.apiUrl + '/api/Election/votingRegister';
        let element = "";
        
        for (let index = 0; index < result.electionApplicant.ueaNid.length; index++) {
             element += "UeaNid=" + result.electionApplicant.ueaNid[index] + "&";
        }

        path += "?" + element + "UaaNid=" + result.assemblyAsistant.uaaNid;

        return this.http.post(path, result, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }

    getAssistantsByElection(objElections: Elections) {
        let path = environment.apiUrl + '/api/Election/AssistantsByElection' + 
        (objElections.eleNid == null ? '' : '?EleNid=' + objElections.eleNid);
        

        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Assistant[]) =>
                    data.map(
                        (item: Assistant) =>
                            new Assistant(item.assNid,
                                            item.assNidentificacion,
                                            item.assCname,
                                            item.assCagency,
                                            item.assDdateCreate,
                                            item.user,
                                            item.typeIdentification
                                )
                    )
                )
            ).toPromise();

    }


    getElectionByAssistant(assNid): Promise<ElectionsPostulate[]>{
    
        this.showSpinner.next(true);
    
        let path = environment.apiUrl + '/api/Election/ElectionByAssistant?AssNid=' + assNid;
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
          .pipe(
            tap(
                response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            map((data: ElectionsPostulate[]) =>
                data.map(
                    (item: ElectionsPostulate) =>
                        new ElectionsPostulate(
                            item.ueaNid,
                            item.ueaNidPost,
                            item.election,
                            item.assemblyAssistant
                            )
                )
            )
        ).toPromise();
      }  
    
    
    authHeaders() {
        var token = new ShareModule().findToken();
        return {
            Authorization: `bearer ` + token,
        }
    }

}