import { Assembly } from '../models/assembly';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map  } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ShareModule } from 'src/modules/share/share.module';
import { AssemblyAssistant } from 'src/models/assemblyassistant';

@Injectable({
    providedIn: "root"
  })

export class AssemblyAssistantService {

    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }

    registry(assemblyassistant: AssemblyAssistant): Promise<any> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Assistant/registry';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.post(path, assemblyassistant, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }

    get(nIdentification: number): Promise<AssemblyAssistant[]> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Assistant/report';
        path += "?nIdentification=" + nIdentification.toString();
        
        let requestOptions = {
            headers: this.authHeaders()
        };

        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: AssemblyAssistant[]) =>
                    data.map(
                        (item: AssemblyAssistant) =>
                            new AssemblyAssistant(item.uaaNid, item.uaaCphoto, item.uaaDdateCreate,
                                item.uaaDdateModified, item.assembly, item.user, item.assistant, item.uaaCtoken)
                    )
                )
            ).toPromise();
    }
    
    authHeaders() {
        return {
            Authorization: `bearer ` + new ShareModule().findToken(),
        }
    }
}