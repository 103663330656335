import { Rol } from './rol';
import { TypeIdentification } from './typeIdentification';
import { User } from './user';

export class Autentication {
    public user: User;
    public token: String;
    public expiration: Date;

    constructor(user?, token?, expiration?) {
        this.user = user;
        this.token = token;
        this.expiration = expiration;
    }
}

