import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ShareModule } from 'src/modules/share/share.module';

@Injectable({
    providedIn: "root"
})
export class UserService {


    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    get(users: User): Promise<User[]> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/user/users';
        path += (users.usuOstate != null) ? '?UsuOstate=' + users.usuOstate : '';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: User[]) =>
                    data.map(
                        (item: User) =>
                            new User(item.usuClogin, item.usuCpassword, item.usuCemail,
                                item.usuNid, item.usuNidentification, item.usuCname,
                                item.usuClastName, item.usuOstate, item.rol, item.typeIdentification)
                    )
                )
            ).toPromise();
    }

    update(user: User): Promise<any> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/User/update';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.post(path, user, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }

    create(user: User): Promise<any> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/User/create';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.post(path, user, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
            ).toPromise();
    }

    authHeaders() {
        var token = new ShareModule().findToken();
        return {
            Authorization: `bearer ` + token,
        }
    }

}