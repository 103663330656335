import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Autentication } from 'src/models/autentication';

@Injectable({
    providedIn: "root"
})
export class LoginService {


    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }

    autentication(users: User): Promise<Autentication[]> {
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Authentication/Login';

        return this.http.post(path, users)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Autentication[]) =>
                    data.map(
                        (item: Autentication) =>
                            new Autentication(item.user, item.token, item.expiration)
                    )
                )
            ).toPromise();
    }


}