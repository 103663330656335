import { User } from './user';
import { Questions } from './questions';
import { Answers } from './answers';

export class QuestionsAnswers {

    public uqaNid: number;
    public queNid: number;
    public ansNid: number;
    public answer: Answers;
    public question: Questions;
      
    public user: User;    

    constructor(pUqaNid?,
        pQueNid?,
        pAnsNid?,
        pAnswers?,
        pQuestion?,
        pUser?)
    {
        this.uqaNid = pUqaNid,
        this.queNid = pQueNid,
        this.ansNid = pAnsNid,
        this.answer = pAnswers,
        this.question = pQuestion,
        this.user = pUser
    }

    

}