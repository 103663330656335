import { User } from './user';

export class Post{

    public posNid: number;
    public posCdescription: number;
    public posOstate: boolean;
    public posDdateModified: Date;
    public posDdateCreate: Date;
    public user: User;

    constructor(pPosNid?,
        pPosCdescription?,
        pPosOstate?,
        pPuser?,
        pPosDdateModified?,
        pPosDdateCreate?){
            this.posNid = pPosNid;
            this.posCdescription = pPosCdescription;
            this.posOstate = pPosOstate;
            this.user = pPuser;
            this.posDdateModified = pPosDdateModified;
            this.posDdateCreate = pPosDdateCreate;
    }
}

