export class TypeIdentification {
    public tipNid: number;
    public tipCdescription: string;
    public tipOstate: Boolean;

    constructor(TipNid?, TipCdescription?, TipOstate?) {
        this.tipNid = TipNid;
        this.tipCdescription = TipCdescription;
        this.tipOstate = TipOstate;
    }
}
