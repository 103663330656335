import { User } from './user';
import { Assembly } from './assembly';
import { Assistant } from './assistant';


export class AssemblyAssistant {
    public uaaNid: number;
    public uaaCphoto: string;
    public uaaDdateCreate: Date;
    public uaaDdateModified: Date;
    public assembly: Assembly;
    public user: User;
    public assistant: Assistant;
    public uaaCtoken: string;

    constructor(uaaNid?, uaaCphoto?, uaaDdateCreate?, uaaDdateModified?,
        assembly?, user?, assistant?, uaaToken?) {
        this.uaaNid = (uaaNid == null ? 0 : uaaNid);
        this.uaaCphoto = uaaCphoto;
        this.uaaDdateCreate = uaaDdateCreate;
        this.uaaDdateModified = uaaDdateModified;
        this.assistant = (assistant == null ? new Assistant() : assistant);
        this.assembly = (assembly == null ? new Assembly() : assembly);
        this.user = (user == null ? new User() : user);
        this.uaaCtoken = uaaToken;
    }
}
