import { User } from './user';
import { Assembly } from './assembly';
import { TypeQuestion } from './typeQuestion';

export class Questions {

    public queNid: number;
    public queCdescription: string;
    public queOstate: boolean;
    public queDdateCreate: Date;
    public queDdateModified: Date;
    public queOactiveQuestion: boolean;
    public queNtimeDuration: number;
    public typeQuestion: TypeQuestion;
    
    public user: User;
    public assembly: Assembly

    constructor(pQueNid?,
        pQqueCdescription?,
        pQueOstate?,
        pQueDdateModified?,
        pQueOactiveQuestion?,
        pQueDdateCreate?,
        pUser?,
        pAssembly?,
        pQueNtimeDuration?,
        typeQuestion?
        ){
                this.queNid = pQueNid,
                this.queCdescription = pQqueCdescription,
                this.queOstate = pQueOstate,
                this.queDdateModified = pQueDdateModified,
                this.queOactiveQuestion = pQueOactiveQuestion,
                this.queDdateCreate = pQueDdateCreate,
                this.user = pUser,
                this.assembly = pAssembly, 
                this.queNtimeDuration = pQueNtimeDuration,
                this.typeQuestion = typeQuestion
    }

}

