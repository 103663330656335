
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Rol } from 'src/models/rol';
import { ShareModule } from 'src/modules/share/share.module';

@Injectable({
    providedIn: "root"
})
export class RolService {


    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    get(RolObject: Rol): Promise<Rol[]> {

        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Rol/roles?';
        path += (RolObject.rolOstate != null) ? 'rolOstate=' + RolObject.rolOstate : '';
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Rol[]) =>
                    data.map(
                        (item: Rol) =>
                            new Rol(item.rolNid, item.rolCdescription, item.rolOstate)
                    )
                )
            ).toPromise();
    }
    
    authHeaders() {
        return {
            Authorization: `bearer ` + new ShareModule().findToken(),
        }
    }
}