import { Component, OnInit, Injectable } from '@angular/core';
import { ShareModule } from 'src/modules/share/share.module';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

@Injectable()
export class NavbarComponent implements OnInit {
  public mostrarMenus: boolean = true;
  isShown: boolean = false;
  isAdmin: boolean = (new ShareModule().getRol() == 1 ? true : false);
  isRegister: boolean = (new ShareModule().getRol() == 2 ? true : false);
  isClick: boolean = false;
  constructor() { }

  ngOnInit() {
    this.mostrarMenus = new ShareModule().getIsLogin();
  }

  clic() {
    this.isClick = true;
  }
}
