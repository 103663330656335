
import { AssistantReport } from './assistantreport';


export class AutenticationAssistant {
    public assistant: AssistantReport;
    public token: String;
    public expiration: Date;

    constructor(assistant?, token?, expiration?) {
        this.assistant = assistant;
        this.token = token;
        this.expiration = expiration;
    }
}

