import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Optional, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appInputMaxLength]'
})

export class InputMaxLengthDirective implements OnInit, OnDestroy{
    @Input() appInputMaxLength: number;
    private destroyed$ = new Subject();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Optional() private ngModel: NgModel,
      ) {}

    @HostListener('input', ['$event']) onChange(event) {}

    ngOnInit() {
        this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.appInputMaxLength.toString());
        if (this.ngModel) {
          this.ngModel.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {})
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
    
}