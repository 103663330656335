import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Questions } from 'src/models/questions';
import { Answers } from 'src/models/answers';
import { ShareModule } from 'src/modules/share/share.module';
import { QuestionsAnswers } from 'src/models/questionsAnswers';
import { ResponseQuestionAssembly } from 'src/models/responsequestionassembly';

@Injectable({
    providedIn: "root"
})


export class AnswersService{
    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }


    getAnswersState(objAnswers): Promise<Answers[]> {
        
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Answer/getAnswersState' + (objAnswers.ansOstate == null ? '' : '?state=' + objAnswers.ansOstate); 
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Answers[]) =>
                    data.map(
                        (item: Answers) =>
                            new Answers(item.ansNid,
                                item.ansCdescription,
                                item.ansOstate,
                                item.ansDdateCreate,
                                item.ansDdateModified,
                                item.user
                                )
                    )
                )
            ).toPromise();
    }

    getAnswers(idQuestion: number): Promise<QuestionsAnswers[]> {
        
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Answer/answers?QueNid=' + idQuestion;
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: QuestionsAnswers[]) =>
                    data.map(
                        (item: QuestionsAnswers) =>
                            new QuestionsAnswers(item.uqaNid,
                                item.queNid,
                                item.ansNid,
                                item.answer, 
                                item.question,
                                item.user
                                )
                    )
                )
            ).toPromise();
    }

    saveAnswers(objAnswers: Answers): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = "";
        
        if(objAnswers.ansNid == 0){
            path = environment.apiUrl + '/api/Answer/create';
        } 
        else{
            path = environment.apiUrl + '/api/Answer/update';
        }

        return this.http.post(path, objAnswers, requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
        ).toPromise();
    }
    
    setAnswerAssitant(response: ResponseQuestionAssembly): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = environment.apiUrl + '/api/Answer/createResponseQuestionAssembly';
        
        return this.http.post(path, response, requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
        ).toPromise();
    }

    setAssocite(response: QuestionsAnswers): Promise<any> {
        this.showSpinner.next(true);
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = environment.apiUrl + '/api/Answer/createQuestionAnswer';
        
        return this.http.post(path, response, requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
        ).toPromise();
    }

    setDesassocite(response: QuestionsAnswers): Promise<any> {
        this.showSpinner.next(true);
        
        let requestOptions = {
            headers: this.authHeaders()
        };
        let path = environment.apiUrl + '/api/Answer/deleteQuestionAnswer';
        
        return this.http.post(path, response,requestOptions)
        .pipe(
            tap(
            response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
        ).toPromise();
    }



    authHeaders() {
        var token = new ShareModule().findToken();
        return {
            Authorization: `bearer ` + token,
        }
    }

}
