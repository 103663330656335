import { User } from "./user";

export class Assembly {

    public asaNid: number;
    public asaCname: string;
    public asaCdescription: string;
    public asaDdateAssembly: Date;
    public asaDdateHourInitial: Date;
    public asaDdateHourFinalition: Date;
    public asaOstate: boolean;
    public asaDdateModified: Date;
    public asaDdateCreate: Date;
    public user: User;

    constructor(pAsa_nid?, pAsa_Cname?, pAsa_Cdescription?, pAsa_Ddate_assembly?, pAsa_Ddate_hour_initial?, 
        pAsa_Ddate_hour_finalition?, pAsa_Ostate?, pUserAssembly?, pAsa_Ddate_modified?, pAsa_DdateCreate?) {
        
        this.asaNid = (pAsa_nid == null ? 0 : pAsa_nid);
        this.asaCname = pAsa_Cname;
        this.asaCdescription = pAsa_Cdescription;
        this.asaDdateAssembly = pAsa_Ddate_assembly;
        this.asaDdateHourInitial = pAsa_Ddate_hour_initial;
        this.asaDdateHourFinalition = pAsa_Ddate_hour_finalition;
        this.asaOstate = pAsa_Ostate;
        this.asaDdateModified = pAsa_Ddate_modified;
        this.asaDdateCreate = pAsa_DdateCreate;
        this.user = pUserAssembly;

    }
}