import { Rol } from './rol';
import { TypeIdentification } from './typeIdentification';

export class User {
    public nPhone: number;
    public cMessage: string;

    public usuNid: number;
    public usuNidentification: number;
    public usuClogin: string;
    public usuCpassword: string;
    public usuCname: string;
    public usuClastName: string;
    public usuDdateCreation: Date;
    public usuCemail: string;
    public usuOstate: boolean;
    public usuDdateModified: Date;

    public rol: Rol;
    public typeIdentification: TypeIdentification;

    constructor(usuClogin?, usuCpassword?, usuCemail?, usuNid?,
        usuNidentification?, usuCname?, usuClastName?, usuOstate?,
        rol?, typeIdentification?, usuDdateModified?, usuDdateCreation?) {

        this.usuClogin = usuClogin;
        this.usuCpassword = usuCpassword;
        this.usuCemail = usuCemail;
        this.usuNid = (usuNid == null ? 0 : usuNid);
        this.usuNidentification = usuNidentification;
        this.usuCname = usuCname;
        this.usuClastName = usuClastName;
        this.usuOstate = usuOstate;
        this.usuDdateModified = usuDdateModified;
        this.usuDdateCreation = usuDdateCreation;
        this.rol = (rol == null ? new Rol() : rol);
        this.typeIdentification = (typeIdentification == null ? new TypeIdentification() : typeIdentification);
    }
}
