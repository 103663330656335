export class Rol {
    public rolNid: number;
    public rolCdescription: string;
    public rolOstate: Boolean;
    public rolDdateCreate: Date;
    public rolDdateModified: Date;

    constructor(RolNid?, RolCDescription?, RolOstate?, rolDdateCreate?, rolDdateModified?) {
        this.rolNid = RolNid;
        this.rolCdescription = RolCDescription;
        this.rolOstate = RolOstate;
        this.rolDdateCreate = rolDdateCreate;
        this.rolDdateModified = rolDdateModified;
    }
}

