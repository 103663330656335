import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Assistant } from 'src/models/assistant';
import { ShareModule } from 'src/modules/share/share.module';
import { AutenticationAssistant } from 'src/models/autenticationassistant';
import { debug } from 'util';

@Injectable({
    providedIn: "root"
})
export class AssistanceService {


    public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) { }

    getforIdentification(assistant: Assistant): Promise<Assistant[]> {
        
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Assistant/assistants?';
        path += 'AssNidentificacion=' + assistant.assNidentificacion;
        path += '&AssOstate=true'
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: Assistant[]) =>
                    data.map(
                        (item: Assistant) =>
                            new Assistant(item.assNid, item.assNidentificacion, item.assCname, item.assCagency, item.assDdateModified,
                                item.assDdateCreate, item.user, item.typeIdentification, item.assOstate, item.assCemail, item.assCphone, item.agency)
                    )
                )
            ).toPromise();
    }

    autentication(obj: Assistant): Promise<AutenticationAssistant[]> {
        
        this.showSpinner.next(true);
        //let path = environment.apiUrl + '​/api/Assistant/login';
        let path = environment.apiUrl + '/api/Assistant/login?cToken=' + obj.token + '&nIdentification=' + obj.assNidentificacion;
        return this.http.post(path, obj)
            .pipe(
                tap(
                    response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map((data: AutenticationAssistant[]) =>
                    data.map(
                        (item: AutenticationAssistant) =>
                            new AutenticationAssistant(item.assistant, item.token, item.expiration)
                    )
                )
            ).toPromise();
    }

    resendAccessToken(asaNid): Promise<any>{
        this.showSpinner.next(true);

        
        this.showSpinner.next(true);
        let path = environment.apiUrl + '/api/Assistant/resendAccessToken?AssNid=' + asaNid;
        let requestOptions = {
            headers: this.authHeaders()
        };
        return this.http.get(path, requestOptions)
        .pipe(
            tap(
                response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false)),
                map(resp => {
                  return <any>resp;
                  })
        )   
        .toPromise();
    }
    

    authHeaders() {
        return {
            Authorization: `bearer ` + new ShareModule().findToken(),
        }
    }
}